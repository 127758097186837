$primary_color: #005585;
$font-color: #444;

html,
body {
  width: 100%;
  height: 100%;
}

@import 'universal';
@import 'layout';
