$bg-color1: #f6f6f6;

a,
a:visited {
  color: #333;
  text-decoration: none;
}

a:hover {
  color: #c00;
  text-decoration: none;
  transition: all, 0.2s;
}

a:active {
  text-decoration: none;
}

a:focus {
  outline: none;
  text-decoration: none;
}

a:link {
  text-decoration: none;
}

:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.fl {
  float: left;
}

.fr {
  float: right;
}

.bd-navbar {
  padding: 1.25rem 4rem;
  background-color: #132f93;

  img {
    width: 7.375rem;
    height: 3.125rem;
  }

  .navbar-menu {
    width: calc(100% - 5rem);
    .navbar-start {
      width: inherit;
      justify-content: flex-end;
      a,
      a:visited {
        color: #fff;
      }

      a.navbar-item:hover {
        color: #00f0ff;
        background-color: rgba(255, 255, 255, 0.25);
      }
      .navbar-item {
        padding: 0.625rem 2rem;
      }
    }
  }
  .navbar-burger {
    color: #fff;
  }
}

.section_fluid {
  background-color: $bg-color1;
  padding: 4rem 3.75rem;
  h2 {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
  }
  p {
    font-size: 1.5rem;
    text-align: center;
    color: #777;
  }
}

.slide_img,
.slide_img img {
  width: 100%;
  height: auto;
}

.title {
  padding: 2.25rem 0;
  text-align: center;
  position: relative;

  h3 {
    font-size: 32px;
    position: relative;
    z-index: 100;
    font-weight: bold;
  }
  h6 {
    font-size: 24px;
    color: #e9e9e9;
    position: relative;
    top: -12px;
    z-index: 10;
  }
}
.product {
  margin-bottom: 2.5rem;
  padding: 3.125rem 0;
  img {
    display: block;
    width: 12.5rem;
    height: 12.5rem;
    margin: 0 auto 1.25rem;
  }
  h5 {
    text-align: center;
    width: 100%;
  }
}

.solution {
  &_item {
    padding: 4rem 2rem;
    border-radius: 1rem;
    background-position: center center;
    background-size: cover;
    span {
      color: #ffffff;
      font-size: 1.75rem;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: right;
    }
  }
  &_1 {
    background-image: url(../../assets/images/solution_1.png);
  }
  &_2 {
    background-image: url(../../assets/images/solution_2.png);
  }
  &_3 {
    background-image: url(../../assets/images/solution_3.png);
  }
  &_4 {
    background-image: url(../../assets/images/solution_4.png);
  }
}

.bd-footer {
  // display: flex;
  // flex-flow: row nowrap;
  // align-items: center;
  position: relative;
  padding: 1.5rem 0;
  &-item {
    height: 9rem;
    padding: 1.25rem 3rem;
  }
  &-item:first-child {
    position: relative;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &-item:last-child {
    position: relative;
    img {
      position: absolute;
      width: 8rem;
      height: 8rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.copyright {
  text-align: center;
  font-size: 14px;
  padding: 0.625rem 0;
  margin-bottom: 1rem;
}

.breadcrumb {
  h4 {
    padding: 1.5rem 0;
    font-size: 28px;
  }
}

.download-list {
  background-color: #f6f6f6;
  padding: 0 0 1.25rem;
  h5 {
    font-size: 22px;
    padding: 1rem 0;
  }

  &-item {
    &-box {
      border: 1px solid #e8e8e8;
      background-color: #fff;
    }
    h6 {
      padding: 1rem 0px;
      text-align: center;
      font-size: 1.125rem;

      color: #005585;
    }
    img {
      width: 150px;
      height: 150px;
      display: block;
      margin: 0 auto;
    }
    a {
      display: block;
      padding: 0.625rem 0;
      text-align: center;
    }
    a:after {
      content: '';
    }
  }
}
.product-list {
  background-color: #f6f6f6;
  padding: 1.25rem 0;

  &-item {
    background-color: #fff;
    padding: 1.25rem;
    margin-bottom: 1.25rem !important;
    img {
      margin: 0 auto;
      width: 12.5rem;
      height: 12.5rem;
      display: block;
    }
    h4 {
      font-size: 1.375rem;
      margin-bottom: 1.25rem;
      color: #132f93;
    }
    p {
      line-height: 1.5;
      text-indent: 2em;
      color: #777;
    }
  }
}
.solution-list {
  background-color: #f6f6f6;
  padding: 1.25rem 0;

  img {
    width: 100%;
    height: auto;
  }

  h4 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 1023px) {
  .bd-navbar {
    padding: 1.25rem 2rem;

    .navbar-menu {
      width: 100%;
      background-color: transparent;
      padding: 0.5rem 0;
    }
  }
  .bd-footer-item {
    height: 11.25rem;
  }
}
